<template>
    <div class="home">
        <!-- 头部 -->
        <myHead></myHead>

        <div class="pjTitle">
            <div class="title">评价订单</div>
            <div class="text">订单号：{{ orderIdInfo.oid }} &nbsp;&nbsp; {{ orderIdInfo.createTime }}</div>
        </div>

        <div class="pj" v-for="(i, index) in orderIdInfo.listOrderItem" :key="index">
            <div class="l">
                <div class="img">
                    <img :src="i.img" alt="">
                </div>
                <div class="name">{{ i.productName }}</div>
                <div class="jg">
                    <!-- <div class="red">¥1800.00</div> -->
                    <!-- <div class="tet">销量 526</div> -->
                    <div class="tet">{{ i.valueDetailTxt }}</div>
                </div>
            </div>
            <div class="r">
                <div class="titl">物流服务评价</div>
                <div class="son">
                    物流服务
                    <div class="rate">
                        <el-rate v-model="i.logisticsValue" show-text :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
                        </el-rate>
                    </div>
                </div>
                <div class="son">
                    服务态度
                    <div class="rate">
                        <el-rate v-model="i.consultValue" show-text :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
                        </el-rate>
                    </div>
                </div>
                <div class="titl">产品评价</div>
                <div class="son">
                    产品相符
                    <div class="rate">
                        <el-rate v-model="i.qualityValue" show-text :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
                        </el-rate>
                    </div>
                </div>
                <div class="son">
                    评价晒单
                    <div class="rate">
                        <el-input type="textarea" :rows="4" maxlength="200" show-word-limit
                            placeholder="从多角度评价宝贝，可能给更多人带来帮助" v-model="i.evaluateContent">
                        </el-input>
                    </div>
                </div>

                <div class="upImg" @click="actIndex(index)">
                    <div class="prompt">共 <span>{{ i.evaluateImage == '' ? 0 : i.evaluateImage.split(',').length }}</span>
                        张，还能上传 <span>9</span> 张</div>
                    <el-upload :limit="9" action="https://api.cnc-x.cn//mall/uploads" list-type="picture-card"
                        :data="{ type: '1' }" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :on-change="handleChange" :on-exceed="onExceed">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </div>
            </div>
        </div>
        <div class="fab" @click="faBuPJ">
            <div>发布评价</div>
        </div>

        <!-- 底部 -->
        <bottomContent></bottomContent>
    </div>
</template>

<script>
import { getOrderIdInfo, postEvaluate } from '@/utils/api/myApi/index'
import myHead from "@/components/myHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'orderDetails',
    components: {
        myHead,
        bottomContent
    },
    data() {
        return {
            // 上传
            dialogImageUrl: '',
            dialogVisible: false,
            // 订单号
            oid: '',
            // 订单详情
            orderIdInfo: {},
            // 当前输入的索引
            actInd: 0,

        }
    },
    mounted() {
        this.oid = this.$route.query.oid
        // 拿订单信息
        this.getOrderIdInfo()
    },
    methods: {
        onExceed(files, fileList) {
            this.$message.error('最多上传9张')
        },
        // 当前输入的索引
        actIndex(i) {
            // console.log(i)
            this.actInd = i
        },
        handleRemove(file, fileList) {
            console.log(file, fileList, '删除');
            this.handleChange(file, fileList)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleChange(file, fileList) {
            console.log(fileList.map(f => f.response?.data).join(','), '变化')
            this.orderIdInfo.listOrderItem[this.actInd].evaluateImage = fileList.map(f => f.response?.data).join(',')
        },
        // 发布
        async faBuPJ() {
            let listOrderItem = this.orderIdInfo.listOrderItem
            listOrderItem.map(d => {
                var zFen = 0
                if (d.evaluateContent == '') return this.$message.error('请填写评价内容')
                zFen = ((d.consultValue + d.logisticsValue + d.qualityValue) / 3).toFixed(1)
                if (zFen > 4) {
                    d.evaluateType = 'good'
                    d.stoevaluateTypereId = 'good'
                } else if (zFen < 2) {
                    d.evaluateType = 'bad'
                    d.stoevaluateTypereId = 'bad'
                } else {
                    d.evaluateType = 'middle'
                    d.stoevaluateTypereId = 'middle'
                }
                d.total = d.consultValue + d.logisticsValue + d.qualityValue
            })

            const res = await postEvaluate(JSON.stringify(listOrderItem))
            if (res.statusCode == 8201) {
                this.$message.success('评价成功')
                setTimeout(() => {
                    this.$router.go(-1);
                }, 2000); // 延迟2秒执行
            } else {
                this.$message.error('操作失败')
            }

        },
        // 订单信息
        async getOrderIdInfo() {
            const res = await getOrderIdInfo(this.oid)
            if (res.statusCode == 8201) {
                this.orderIdInfo = res.data
                this.orderIdInfo.listOrderItem.map(d => {
                    this.$set(d, 'storeId', this.orderIdInfo.storeId)
                    this.$set(d, 'stoevaluateTypereId', 'good') // 好评中评差评
                    this.$set(d, 'evaluateType', 'good') // 好评中评差评
                    this.$set(d, 'evaluateImage', '') // 评价图片
                    this.$set(d, 'total', '') // 总分
                    this.$set(d, 'consultValue', 5) // 咨询打分
                    this.$set(d, 'logisticsValue', 5) // 物流打分
                    this.$set(d, 'qualityValue', 5) // 质量打分 
                    this.$set(d, 'evaluateContent', '') // 评价内容
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100%;
    object-fit: cover !important;
}

.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.pjTitle {
        width: 1200px;
        height: 110px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px auto;

        >.title {
            font-size: 24px;
            color: #333;
        }

        >.text {
            font-size: 16px;
            color: #999;
            margin-top: 12px;
        }
    }

    >.pj {
        width: 1200px;
        height: auto;
        display: flex;
        margin: 20px auto;
        background-color: #fff;
        padding: 30px 50px;
        box-sizing: border-box;

        >.l {
            width: 200px;
            height: auto;

            >.img {
                width: 200px;
                height: 200px;
                border-radius: 6px;
                border: 1px solid rgba(0, 0, 0, 0.10);
                overflow: hidden;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.name {
                width: 100%;
                // font-size: 12px;
                color: #000;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-top: 13px;
            }

            >.jg {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 8px;

                >.red {
                    font-size: 16px;
                    color: #FF4242;
                    font-weight: bold;
                }

                >.tet {
                    font-size: 12px;
                    color: #666;
                    // margin-left: 6px;
                }
            }
        }

        >.r {
            margin-left: 100px;

            >.titl {
                font-size: 20px;
                color: #333;
                margin-bottom: 30px;
            }

            >.son {
                font-size: 14px;
                color: #333;
                display: flex;
                margin-bottom: 20px;

                >.rate {
                    margin-left: 40px;
                    width: 700px;
                }
            }

            >.upImg {
                margin-left: 100px;

                >.prompt {
                    font-size: 14px;
                    color: #999999;
                    margin-bottom: 5px;

                    >span {
                        color: #FF4242;
                    }
                }
            }
        }

    }

    >.fab {
        width: 1200px;
        height: 100px;
        background-color: #fff;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
            width: 174px;
            height: 44px;
            background-color: #FF4242;
            border-radius: 1px;
            text-align: center;
            line-height: 44px;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
        }
    }

}
</style>